import './Function.css';
import React from 'react'
import FunctionItem from './FunctionItem.js'

export default function FunctionList({
    title,
    description,
    itemList,
}) {
    return (
        <div className='function-wrapper'>
            <div className='function-content'>
                <h3 className='title' dangerouslySetInnerHTML={{ __html: title }}></h3>
                <p className='desc' dangerouslySetInnerHTML={{ __html: description }}></p>
                <div className='function-list'>
                    {itemList && itemList.map((item, index) => {
                        return (
                            <FunctionItem
                                key={index}
                                id={item._id}
                                title={item.title}
                                description={item.description}
                                btnTitle='Detail'
                                btnLink={`#`}
                                image={item.image}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

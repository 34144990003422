import React from 'react'
import { Line } from '@ant-design/plots';

export default function ChartTrends({
    trendList
}) {
    const config = {
        data: trendList,
        xField: 'month',
        yField: 'value',
        seriesField: 'name',
        legend: {
            position: 'top',
        },
        label: {},
        yAxis: {
            label: {
                // 数值格式化为千分位
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
            title: {
                // text: showData.y_label,
                text: 'Electricity Savings($CAD)',
            },
        },
        xAxis: {
            title: {
                text: 'Month',
            },
        },
        point: {
            size: 5,
            shape: 'dot',
            style: {
                fill: '#55B670',
                stroke: '#55B670',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        // state: {
        //     active: {
        //         style: {
        //             shadowBlur: 4,
        //             stroke: '#F59D00',
        //             fill: '#F59D00',
        //         },
        //     },
        // },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
        /** 设置颜色 */
        color: ['#55B670'],
        smooth: true,
        // @TODO 后续会换一种动画方式
        // animation: {
        //     appear: {
        //         animation: 'path-in',
        //         duration: 3000,
        //     },
        // }
    };

    return (
        <div className='chart-wrap'>
            <h3>Trends Of Energy Savings</h3>
            <Line {...config} />
        </div>

    )
}

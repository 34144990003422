import './LandingFooter.css'
import React from 'react'

export default function LandingFooter() {
    return (
        <footer>
            <div className='landing-footer-content'>
                <img src='./images/logo.png' width='100' alt='' />
                <p>Email:xxxx Phone: xxxx</p>
                <p>Copyright © 2022 AI-Shading. All Rights Reserved.</p>
            </div>
        </footer>
    )
}

import './Landing.css'
import React from 'react'
import CommonSection from '@components/CommonSection'
import LandingData from './data/LandingData'
import LandingHeader from '@components/LandingHeader'
import LandingHeroSection from '@components/LandingHeroSection'
import LandingFooter from '@components/LandingFooter'

export default function Landing() {

    return (
        <>
            <LandingHeader />
            <LandingHeroSection {...LandingData.heroSection} />
            {/* <div className='landing-content'>
                <h3>AI Shading</h3>
                <p>Apply for the ERA program and you will receive government grants.</p>
            </div> */}
            <CommonSection
                key='contact-section'
                color='#fff'
                title="Emissions Reduction Alberta"
                description="Alberta is on the mission to cut down carbon emissions in buildings, the new government funded intelligent window shading pilot project is designed to help building managers save money on heating and cooling bills. Selected qualifying buildings will be given the opportunity to save up to 100% on the energy efficient smart shading retrofit."
                // image='/images/emissions-reductions-alberta.jpg'
                // imageAlt="Contact Us"
                // imageAlign='left'
                imageAlign='right'
                videoUrl='/videos/AI Shading Infomercial FINAL.mp4'
            />
            <CommonSection
                key='common-section'
                color='#f5f5f5'
                title="Delivering results the world needs."
                description="This easy yet powerful energy savings simulation is developed through extensive research by our group of engineers. In as little as five minutes, you can find out about your building’s potential energy savings and sign up for the pilot project funded by the Emissions Reduction Alberta. "
                btnTitle=''
                btnLink=''
                image='/images/emissions-reductions-alberta.jpg'
                imageAlt="Contact Us"
                imageAlign='left'
            />
            <CommonSection
                key='about-section'
                color='#FFF'
                title="About Us"
                description="AI Shading is a Canadian Smart & Green Technology company that provides economical solutions to help commercial buildings reach their emission reduction target by regulating indoor temperatures through a combination of smart indoor shades, sensors, mobile apps, and energy management platforms. AI Shading equips building managers with energy-saving reports to better analyze energy usage and track emission reduction goals."
                btnTitle=''
                btnLink=''
                // image='/images/about.jpg'
                // imageAlt="About Us"
                imageAlign='right'
                videoUrl='/videos/SAIT Videos.mp4'
            />
            <LandingFooter />
        </>
    )
}

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { config } from '../Config'

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // console.log('pathname', pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pathname !== '/' ? `${pathname.slice(1).charAt(0).toUpperCase() + pathname.slice(2)} - ${config.appName}` : config.appName}</title>
      </Helmet>
    </HelmetProvider>
  );
}
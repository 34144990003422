const LandingData = {
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 350,
        title: 'About Us',
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/banner-about.jpg'
    },
    heroSection: {
        color: '#fff',
        align: 'left',
        title: 'AI Shading',
        description: "The time to take action is now, make your first step towards a sustainable future.",
        btnTitle: 'About Us',
        btnLink: '/about',
        background: '/images/landing-hero.jpg'
    },
};

export default LandingData;
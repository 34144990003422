import './About.css'
import React from 'react'
import BannerSection from '@components/BannerSection'
import CommonSection from '@components/CommonSection'
import Certification from '@components/Certification'
import AboutData from './data/AboutData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

export default function Home() {

    return (
        <>
            <Navbar />
            <BannerSection {...AboutData.bannerSection} />
            <CommonSection
                key='common-section'
                color='#f5f5f5'
                title="About Us"
                description="AI Shading is a Canadian Smart & Green Technology company that provides economical solutions to help commercial buildings reach their emission reduction target by regulating indoor temperatures through a combination of smart indoor shades, sensors, mobile apps, and energy management platforms. AI Shading equips building managers with energy-saving reports to better analyze energy usage and track emission reduction goals."
                btnTitle=''
                btnLink=''
                // image='/images/about.jpg'
                // imageAlt="About Us"
                imageAlign='right'
                videoUrl='/videos/AI Shading Infomercial FINAL.mp4'
            />
            <Certification
                key='certification-section'
                color='#fff'
                title="Certification Credits"
                description="AI Shading will help your project gain credits and achieved target certifications."
                image='/images/certification.png'
            />
            <Footer />
        </>
    )
}

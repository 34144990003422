import './head-logo.css'
import React from 'react'
import { config } from '../Config'
import { Link } from 'react-router-dom'

export default function HeadLogo() {
    return (

        <head-logo>

            <div className='head-content'>
                <div className='head-date'>
                    <p>Jan. 17, 2023
                        <br/>
                        Calgary, Alberta</p>

                </div>

                <div className='head-media' >
                    <h3>SHARE THIS ARTICLE</h3>

                        <a href={config.facebook} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-facebook.png" alt="facebook" /></a>
                        <a href={config.instagram} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-instagram.png" alt="instagram" /></a>
                        {/* <li><a href={config.twitter} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-twitter.png" alt="twitter" /></a></li>
                         */}
                       <a href={config.linkin} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-linkin.png" alt="twitter" /></a>
                </div>
            </div>

        >
        </head-logo>

    )
}
import React from 'react'
import { Pie } from '@ant-design/plots';

export default function ChartPerentage({
    perentageData
}) {
    const config = {
        appendPadding: 10,
        data: perentageData,
        angleField: 'value',
        colorField: 'name',
        color: ['#55B670', '#4BB0FF', '#F59D00'],
        radius: 0.75,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
    };

    return (
        <div className='chart-wrap'>
            <h3>Energy Savings Distribution</h3>
            <Pie {...config} />
        </div>

    )
}

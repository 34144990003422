import './LandingHeader.css'
import { Link } from 'react-router-dom'
import { AiFillPhone } from 'react-icons/ai'
export default function LandingHeader() {

    return (
        <header>
            <nav className='handling-navbar'>
                <div className="handling-nav-list">
                    <div className="handling-nav-logo">
                        <Link to="/">
                            <img width="100" src="/images/logo.png" alt="" />
                        </Link>
                    </div>
                    <div className="handling-nav-content">
                        <AiFillPhone size={48} className="handling-nav-content-icon" />
                        <div className="handling-nav-content-phone">
                            <p>CONTACT NOW</p>
                            <p>403-966-6680</p>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

import './App.css';
import { Routes, Route } from 'react-router-dom';
// import Navbar from '@components/Navbar';
// import Footer from '@components/Footer';
import ScrollToTop from '@components/ScrollToTop';
import Home from '@pages/home/Home';
import About from '@pages/about/About';
import Contact from '@pages/contact/Contact';
import Solution from '@pages/solution/Solution';
import Simulation from '@pages/simulation/Simulation';
import Landing from '@pages/landing/Landing';
import Landing2 from '@pages/landing/Landing2';
import News from '@pages/news/News';
import Newsroom from '@pages/newsroom/Newsroom';
import Sustainability from '@pages/sustainability/Sustainability';

function App() {
  return (
    <>
      <ScrollToTop />
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/simulation" element={<Simulation />} />
        <Route path="/news" element={<News/>} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/newsroom" element={<Newsroom />} />
        <Route path="/sustainability" element={<Sustainability />} />
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;

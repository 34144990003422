import './Contact.css'
import React from 'react'
import BannerSection from '@components/BannerSection'
import CommonSection from '@components/CommonSection'
import ContactData from './data/ContactData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

export default function Home() {

    return (
        <>
            <Navbar />
            <BannerSection {...ContactData.bannerSection} />
            <CommonSection
                key='contact-section'
                color='#fff'
                title="Contact Us"
                description="<strong>We want to hear from you.</strong><br/><br/>Unit H, 1423 45 Ave NE<br/>Calgary, Alberta, T2E 2P3<br/><br/>Phone: (403) 966-6680<br/>Email: info@aishading.com"
                btnTitle='Partner Sign Up'
                externalbtnLink='https://forms.gle/1x3XbysJo5r758Ls7'
                image='/images/banner-simulation.jpg'
                imageAlt="Contact Us"
                imageAlign='left'
            />
            <Footer />
        </>
    )
}

import './RedSection.css';
import React from 'react'
import { Link } from 'react-router-dom'

export default function RedSection({
    color, title, subTitle, btnTitle, btnLink
}) {
    return (
        <div className='red-wrapper' style={{ backgroundColor: color }} id={title}>
            <div className='red-section'>
                <div className='red-section-text'>
                    <h3>{title}</h3>
                    <h4>{subTitle}</h4>
                    <Link to={btnLink}><button className='white-button'>{btnTitle}</button></Link>
                </div>
            </div>
        </div>
    )
}

import './Simulation.css'
import React, { useState, useEffect } from 'react'
import BannerSection from '@components/BannerSection'
import SimulationData from './data/SimulationData'
import { Row, Col, message } from 'antd'
import Summary from './components/Summary'
import InputForm from './components/InputForm'
import ChartMonths from './components/ChartMonths'
import ChartTrends from './components/ChartTrends'
import ChartPerentage from './components/ChartPerentage'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import { simulation as simulationAPI } from '@services/api.service'
import RcResizeObserver from 'rc-resize-observer';


export default function Simulation() {

    const [simulationData, setSimulationData] = React.useState(null);
    const [responsive, setResponsive] = useState(false);
    const [initData, setInitData] = useState({
        city: 'Calgary',
        window_type: '1b',
        width: 10,
        height: 5,
        windows: [
            { orientation: 'East', number: 10 },
            { orientation: 'South', number: 10 },
            { orientation: 'West', number: 10 }
        ],
        companyName: '',
        email: '',
        fullname: '',
        phoneNumber: ''
    })

    const handleAction = async (params) => {
        try {
            setSimulationData(null);
            const result = await simulationAPI(params);
            console.log(result.data)
            setSimulationData(result.data)
        } catch (error) {
            const text = `Error: ${error.data?.message || 'please try again'}`;
            message.error(text);
        }
    };

    useEffect(() => {
        const loadLanding = async () => {
            const landingData = localStorage.getItem('landingData');
            if (landingData) {
                localStorage.removeItem('landingData');
                const landingObj = JSON.parse(landingData);
                setInitData(landingObj);
                console.log('landingObj', landingObj)
                await handleAction(landingObj)
            }
        }
        loadLanding();
    }, [])

    return (
        <>
            {/*<Navbar />*/}
            {/*<BannerSection {...SimulationData.bannerSection} />*/}
            <RcResizeObserver
                key="resize-observer"
                onResize={(offset) => {
                    setResponsive(offset.width < 900);
                }}
            >
                <div className='simulation-wrap'>
                    <Row style={{ backgroundColor: '#fff', padding: 10 }}>
                        <Col span={responsive ? 24 : 8} style={{ paddingRight: 10 }}>
                            <InputForm initData={initData} handleAction={handleAction} />
                        </Col>
                        {(simulationData && simulationData.summaryList) && (
                            <Col span={responsive ? 24 : 16}>
                                <Summary summaryList={simulationData.summaryList} />
                            </Col>
                        )}
                        {simulationData == null && (
                            <Col span={responsive ? 24 : 16}>
                                <img width="100%" src="/images/banner-simulation.jpg" alt="simulation" />
                            </Col>
                        )}
                    </Row>
                    {(simulationData && simulationData.monthList) && (
                        <Row style={{ backgroundColor: '#fff', padding: 10 }}>
                            <Col span={24}>
                                <ChartMonths monthList={simulationData.monthList} />
                            </Col>
                        </Row>
                    )}
                    <Row style={{ backgroundColor: '#fff', padding: 10 }}>
                        {(simulationData && simulationData.perentage) && (
                            <Col span={responsive ? 24 : 12}>
                                <ChartPerentage perentageData={simulationData.perentage} />
                            </Col>
                        )}
                        {(simulationData && simulationData.trendList) && (
                            <Col span={responsive ? 24 : 12}>
                                <ChartTrends trendList={simulationData.trendList} />
                            </Col>
                        )}
                    </Row>
                </div>
            </RcResizeObserver>
            {/*<Footer />*/}
        </>
    )
}

import './Home.css'
import React from 'react'
import HeroSection from '@components/HeroSection'
import BannerSection from '@components/BannerSection'
import CommonSection from '@components/CommonSection'
import Certification from '@components/Certification'
import FunctionList from '@components/FunctionList'
import RedSection from './components/RedSection'
import HomeData from './data/HomeData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

const itemList = [
    {
        _id: '1',
        title: 'Seamless Integration',
        description: 'Wireless shading that can be installed  at any project stage,  integrate with any smart building platform.',
        image: '/images/item1.jpg',
    },
    {
        _id: '2',
        title: 'Self Control & Customizable',
        description: 'Self controlled shading that gives you peace of mind ; Customizable room , window grouping and more.',
        image: '/images/item2.jpg',
    },
    {
        _id: '3',
        title: 'Energy Saving & Comfort',
        description: 'AI Shading is trained to operate window coverings to maximize energy efficiency, while letting occupants feel comfortable and relaxed.',
        image: '/images/item3.jpg',
    }
]

export default function Home() {

    return (
        <>
            <Navbar />
            <HeroSection {...HomeData.heroSection} />
            <CommonSection
                key='common-section'
                color='#f5f5f5'
                title="About Us"
                description="AI Shading is a Canadian Smart & Green Technology company that provides economical solutions to help commercial buildings reach their emission reduction target by regulating indoor temperatures through a combination of smart indoor shades, sensors, mobile apps, and energy management platforms. AI Shading equips building managers with energy-saving reports to better analyze energy usage and track emission reduction goals."
                btnTitle='Read More'
                btnLink='/about'
                videoUrl='/videos/AI Shading Infomercial FINAL.mp4'
                // image='/images/about.jpg'
                // imageAlt="About Us"
                imageAlign='right'
            />
            <BannerSection {...HomeData.bannerSection} />
            <FunctionList
                title='For Architects'
                description="AI Shading is the best window covering choice for your project. Contact us to discuss your project needs."
                itemList={itemList}
            />
            <RedSection
                key='read-section'
                color='#55B670'
                title='AI Shading is the best window covering choice for your project.'
                subTitle="Contact us to discuss your project needs."
                btnTitle='Contact Us'
                btnLink='/contact'
            />
            <Certification
                key='certification-section'
                color='#f5f5f5'
                title="Certification Credits"
                description="AI Shading will help your project gain credits and achieved target certifications."
                image='/images/certification.png'
            />
            <CommonSection
                key='contact-section'
                color='#fff'
                title="Contact Us"
                description="<strong>We want to hear from you.</strong><br/><br/>Unit H, 1423 45 Ave NE<br/>Calgary, Alberta, T2E 2P3<br/><br/>Phone: (403) 966-6680<br/>Email: info@aishading.com"
                btnTitle='Contact Us'
                btnLink='/about'
                image='/images/banner-simulation.jpg'
                imageAlt="Contact Us"
                imageAlign='left'
            />
            <Footer />
        </>
    )
}

import './News.css'
import React from 'react'
import HeroSection from '@components/HeroSection'
import BannerSection from '@components/BannerSection'
import CommonSection from '@components/CommonSection'
import Certification from '@components/Certification'
import FunctionList from '@components/FunctionList'
import Divider from './conponents/Divider';
import HeadLogo from '@components/head-logo'
// import RedSection from './components/RedSection'
// import HomeData from './data/HomeData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
import DividerData from "./data/DividerData";

const itemList = [
    {
        _id: '1',
        title: 'Seamless Integration',
        description: 'Wireless shading that can be installed  at any project stage,  integrate with any smart building platform.',
        image: '/images/item1.jpg',
    },
    {
        _id: '2',
        title: 'Self Control & Customizable',
        description: 'Self controlled shading that gives you peace of mind ; Customizable room , window grouping and more.',
        image: '/images/item2.jpg',
    },
    {
        _id: '3',
        title: 'Energy Saving & Comfort',
        description: 'AI Shading is trained to operate window coverings to maximize energy efficiency, while letting occupants feel comfortable and relaxed.',
        image: '/images/item3.jpg',
    }
]

export default function News() {

    return (
        <>
            <Navbar />

            <div className="background-color">




            <CommonSection
                key='contact-section'
                color='#fff'
                // title="Press section"
                description="<font size = 6>Press Release</font><br/><strong>Contact: </strong>Liliana Zhou<br/>Media Liaison<br/>+1(403)-700-0055<br/>liliana@aishading.com"
                image='/images/logo.png'
                imageAlign='logo-left'
            />
            <Divider {...DividerData.dividerSectionThick} />

            <CommonSection
                key='contact-section'
                color='#fff'
                description="<font size = 6><strong>CALGARY-BASED GREEN TECH STARTUPRECOGNIZED BY SDTC AS HIGH POTENTIAL
                SEEDLING FOR SUSTAINABLE GROWTH</strong></font><br/><i>Sustainable Development Technology Canada (SDTC) announced their quarterly seed funding program for 25 technology startup companies in Canada.</i>"

                imageAlign='right'
            />
            <Divider {...DividerData.dividerSectionThin} />
            <HeadLogo/>

            <div className={"article"}>
                <p>
                    Today, Sustainable Development Technology Canada (SDTC) announced Seed Funding of $2.5
                    million for 25 Canadian companies developing new technologies to fight the effects of climate
                    change, contribute to the circular economy, and promote the well-being of people and the
                    protection of nature.
                </p>
                <br/>
                <p>
                    AI Shading has been recognized by SDTC for its intelligent window shading solution that helps
                    property managers optimize building energy efficiency and has been successfully piloted at
                    Canada’s top research institutions. Click <a href="https://www.aishading.com/solution"><u>HERE</u></a> to learn more about AI Shading's pilot project at
                    the SAIT Green Building technologies.
                </p>
                <br/>
                <p>
                    AI Shading is working towards providing economical solutions to help commercial buildings
                    reach their emission reduction target by regulating indoor temperatures through a
                    combination of smart indoor shades, sensors, mobile apps, and energy management
                    platforms. AI Shading's energy saving solution provides building managers with energy-saving
                    reports to better analyze property energy usage and track emission reduction goals.
                </p>
                <br/>
                <p>
                    <a href={"https://www.sdtc.ca/en/seed-fund-application-guide/"}><u>SDTC Seed funding</u></a> supports promising early-stage Canadian sustainable tech entrepreneurs
                    by providing grants of $50,000 to $100,000 to innovative technology projects with the potential
                    to generate economic and environmental benefits for Canada. Applicants for seed funding are
                    nominated by SDTC’s partnership with Canadian accelerators, incubators, and entrepreneurial
                    service organizations.
                </p>
                <br/>
                <p>
                    "<i>DTC is privileged to work with and support entrepreneurs and their companies at every
                    stage of development. Our unique Seed Funding stream allows us to invest in companies
                    exploring bold new areas of innovation, enabling them to further prove their solutions, build
                    their business, and create a pipeline of opportunities for Canadian IP creation for immediate
                    and long-term sustainable technology solutions.</i>”
                </p>
                <p>
                    <strong>–Leah Lawrence, President, and CEO of SDTC</strong>
                </p>
            </div>

                <div className={"simulation-Page-Link"}>
                    <br/>
                    <p >To learn more about AI Shading's Energy Saving solution, try our <a href={"https://aishading.com/simulation"}><u>SIMULATION</u></a>.</p>
                </div>

            </div>





            <Footer />

        </>
    )
}

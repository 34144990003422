import './HeroSection.css';
import React from 'react'
import { Link } from 'react-router-dom'

export default function HeroSection({
    color, align, title, description, btnTitle, btnLink, background
}) {
    const styles = {
        backgroundImage: `url('${background}')`,
        color: color,
        textAlign: align
    }
    return (
        <div className='hero-section' style={styles}>
            {/* <div className='hero-section-wrapper'> */}
                <div className='hero-section-content'>
                    <h3 className='fadein' dangerouslySetInnerHTML={{__html: title}}></h3>
                    <p className='fadein'>{description}</p>
                    <Link to={btnLink} className='link-button'><button className='common-button fadein'>{btnTitle}</button></Link>
                </div>
            {/* </div> */}
        </div>
    )
}

import { config } from '../Config'
const axios = require('axios');

const httpClient = axios.create({
    baseURL: config.appBaseUrl,
    timeout: 5 * 1e3,
});

export async function simulation(values) {
    try {
        const res = await httpClient.post(`/api/v1/simulation`, values);
        return res.data;
    } catch (error) {
        throw error.response;
    }
}

export async function message(values) {
    try {
        const res = await httpClient.post(`/api/v1/message`, values);
        return res.data;
    } catch (error) {
        throw error.response;
    }
}
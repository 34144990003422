import React from 'react'
import { Table, Alert } from 'antd';

export default function Summary({
    summaryList
}) {

    // const data = [
    //     {
    //         key: '1',
    //         season: 'Winter',
    //         saving_gj: 12056.76,
    //         oritation: 'East',
    //         money_saving: 0
    //     },
    //     {
    //         key: '2',
    //         season: 'Winter',
    //         saving_gj: 12056.76,
    //         oritation: 'South',
    //         money_saving: 0
    //     },
    //     {
    //         key: '3',
    //         season: 'Winter',
    //         saving_gj: 12056.76,
    //         oritation: 'West',
    //         money_saving: 0
    //     },
    //     {
    //         key: '4',
    //         season: 'Summer',
    //         saving_gj: 19010.32,
    //         oritation: 'East',
    //         money_saving: 0
    //     },
    //     {
    //         key: '5',
    //         season: 'Summer',
    //         saving_gj: 19010.32,
    //         oritation: 'South',
    //         money_saving: 0
    //     },
    //     {
    //         key: '6',
    //         season: 'Summer',
    //         saving_gj: 19010.32,
    //         oritation: 'West',
    //         money_saving: 0
    //     }
    // ]

    const columns = [
        // {
        //     title: 'Season',
        //     dataIndex: 'season',
        // },
        {
            title: 'Orientation',
            dataIndex: 'orientation',
        },
        {
            title: 'Gigajoule',
            dataIndex: 'total_GJm2',
        },
        {
            title: 'Electricity Savings',
            dataIndex: 'total_cost',
            render: (_, value) => {
                return `$${value.total_cost}`;
            },
        },
    ];

    return (
        <div className='summary-wrap'>
            <h3>Summary Of Energy Savings</h3>
            <Table columns={columns} dataSource={summaryList} pagination={false} />
            <Alert
                message="The simulation calculation was successful"
                description = " "
                type="success"
                showIcon
            />

            <Alert
                // message="The simulation calculation was successful"
                description={`1. Please fill in your email to get a FREE detailed report. `}
                type="success"
                showIcon
            />
            <Alert
               description={`2. Reach out to us to have a coffee talk of energy savings and CO2 reduction by AI shading.`}
               type="success"
               showIcon
            />



        </div>
    )
}

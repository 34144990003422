import './Certification.css';
import React from 'react'

export default function Certification({
    title,
    color,
    description,
    image
}) {
    return (
        <div className='certification-wrapper' style={{ backgroundColor: color }}>
            <div className='certification-content'>
                <h3 className='certification-title' dangerouslySetInnerHTML={{ __html: title }}></h3>
                <p className='certification-desc' dangerouslySetInnerHTML={{ __html: description }}></p>
                <img className='certification-image' src={image} alt="Certification" />
            </div>
        </div>
    )
}

import './CommonSection.css';
import React from 'react'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

export default function CommonSection({
    color, title, description, btnTitle, btnLink, image, imageAlt, imageAlign,
    videoUrl, externalbtnLink
}) {
    const textAlign = (image) ? 'left' : 'center';
    return (
        <div className='section-wrapper' style={{ backgroundColor: color }} id={title}>
            <div className='common-section'>
                {imageAlign === 'right' && (
                    <>
                        <div className='common-section-text'>
                            <h3>{title}</h3>
                            <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: description }}></p>
                            {btnTitle &&
                                (externalbtnLink ?
                                <a href={externalbtnLink} target="_blank" rel="noreferrer"><button className='common-button'>{btnTitle}</button></a> :
                                <Link to={btnLink}><button className='common-button'>{btnTitle}</button></Link>)
                            }
                        </div>
                        { }
                        {image &&
                            <div className='common-section-image'>
                                <img src={image} alt={imageAlt} />
                            </div>
                        }
                        {videoUrl && (
                            <div className='common-section-image'>
                                <ReactPlayer width={'100%'} playing={true} muted={true} controls url={videoUrl} />
                            </div>
                        )}
                    </>
                )}
                {imageAlign === 'left' && (
                    <>
                        {image &&
                            <div className='common-section-image'>
                                <img src={image} alt={imageAlt} />
                            </div>
                        }
                        {videoUrl && (
                            <div className='common-section-image'>
                                <ReactPlayer width={'100%'} playing={true} muted={true} controls url={videoUrl} />
                            </div>
                        )}
                        <div className='common-section-text'>
                            <h3>{title}</h3>
                            <p style={{ textAlign: textAlign }} dangerouslySetInnerHTML={{ __html: description }}></p>
                            {btnTitle &&
                                (externalbtnLink ?
                                    <a href={externalbtnLink} target="_blank" rel="noreferrer"><button className='common-button'>{btnTitle}</button></a> :
                                    <Link to={btnLink}><button className='common-button'>{btnTitle}</button></Link>)
                            }
                        </div>
                    </>
                )}
                {imageAlign === 'middle' && (
                    <>
                        {image &&
                            <div className='common-section-image'>
                                <img src={image} alt={imageAlt} />
                            </div>
                        }
                        {videoUrl && (
                            <div className='common-section-image'>
                                <ReactPlayer width={'100%'} playing={true} muted={true} controls url={videoUrl} />
                            </div>
                        )}

                    </>
                )}
                {imageAlign === 'logo-left' && (
                    <>
                        <div className='common-section-text'>
                            <h3>{title}</h3>
                            <p style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: description }}></p>
                            {btnTitle &&
                            (externalbtnLink ?
                                <a href={externalbtnLink} target="_blank" rel="noreferrer"><button className='common-button'>{btnTitle}</button></a> :
                                <Link to={btnLink}><button className='common-button'>{btnTitle}</button></Link>)
                            }
                        </div>
                        { }
                        {image &&
                            <div className='common-section-logo-left'>
                                <img src='./images/logo.png' width='250' alt='' />
                            </div>
                        }
                        {videoUrl && (
                            <div className='common-section-image'>
                                <ReactPlayer width={'100%'} playing={true} muted={true} controls url={videoUrl} />
                            </div>
                        )}
                    </>
                )}



            </div>
        </div>
    )
}

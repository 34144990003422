const AboutData = {
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 350,
        title: 'About Us',
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/banner-about.jpg'
    }
};

export default AboutData;
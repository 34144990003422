import React, { useEffect } from 'react'
import { Form, Button, Input, Select, Space, message } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import SimulationData from '../data/SimulationData'
import ReportForm from './ReportForm'

export default function InputForm({
    handleAction,
    initData
}) {

    const [form] = Form.useForm();
    const [orientation, setOrientation] = React.useState('North');
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const onSubmit = (values) => {
        console.log('onSubmit', values)
        const datas = form.getFieldsValue(true);
        handleAction({
            ...datas,
            ...values
        });
    }

    const onFinish = (values) => {
        if (values.windows == null || values.windows.length === 0) {
            message.error('Please add an orientation');
            return;
        }
        const getReportInfo = localStorage.getItem('reportInfo');
        if (getReportInfo) {
            const reportInfo = JSON.parse(getReportInfo);
            onSubmit(reportInfo);
            return;
        }
        setIsModalOpen(true);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (form) {
            form.setFieldsValue(initData)
        }
    }, [form, initData])

    return (
        <>
            <ReportForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onSubmit={onSubmit} />
            <Form
                name="basic"
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <Space>
                    <Form.Item
                        label="City"
                        name="city"
                        // initialValue={SimulationData.recommendedSettings[0]}
                        rules={[
                            {
                                required: true,
                                message: 'please select a city',
                            },
                        ]}

                    >
                        <Select style={{ width: '150px' }} options={SimulationData.recommendedSettings.map(item => ({ value: item, label: item }))} />
                    </Form.Item>
                    <Form.Item
                        label="Window Type"
                        name="window_type"
                        // initialValue={'1b'}
                        rules={[
                            {
                                required: true,
                                message: 'please select a window type',
                            },
                        ]}
                    >
                        <Select style={{ width: '150px' }} options={SimulationData.windowType.map(item => ({ value: item.value, label: item.name }))} />
                    </Form.Item>
                </Space>
                <Form.Item
                    label="Window Size (ft)"
                >
                    <Space>
                        Width:
                        <Form.Item
                            name="width"
                            // initialValue={SimulationData.windowSize.width}
                            noStyle={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'please input width',
                                },
                            ]}
                        >
                            <Input
                                placeholder='width'
                                type='number'
                                min={1}
                                style={{ width: '100px' }}
                            />
                        </Form.Item>
                        Height:
                        <Form.Item
                            name="height"
                            // initialValue={SimulationData.windowSize.height}
                            noStyle={true}
                            rules={[
                                {
                                    required: true,
                                    message: 'please input height',
                                },
                            ]}
                        >
                            <Input
                                placeholder='height'
                                type='number'
                                min={1}
                                style={{ width: '100px' }}
                            />
                        </Form.Item>
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Window List"
                >
                    <Form.List
                        name="windows"
                    // initialValue={[
                    //     { orientation: 'East', number: 10 },
                    //     { orientation: 'South', number: 10 },
                    //     { orientation: 'West', number: 10 }
                    // ]}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field) => (
                                    <Space
                                        key={field.key}
                                        style={{
                                            display: 'flex',
                                        }}
                                        align="baseline"

                                    >
                                        {/* Orientation: */}
                                        <Form.Item
                                            {...field.name}
                                            name={[field.name, 'orientation']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'select an orientation',
                                                },
                                            ]}
                                            initialValue={orientation}
                                        >
                                            <Select
                                                style={{ width: '120px' }}
                                                options={SimulationData.windowOrientation.map(item => ({ value: item, label: item }))}
                                            />
                                        </Form.Item>
                                        Number:
                                        <Form.Item
                                            {...field.name}
                                            name={[field.name, 'number']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'input number',
                                                },
                                            ]}
                                            initialValue={10}
                                        >
                                            <Input
                                                type='number'
                                                placeholder="Number"
                                            />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => {
                                        const windows = form.getFieldValue('windows');
                                        if (windows?.length >= 4) {
                                            message.error('Add up to four orientations')
                                            return;
                                        }
                                        const values = form.getFieldsValue(true);
                                        console.log('values', values)
                                        if (values) {
                                            let isFind = false;
                                            SimulationData.windowOrientation.forEach(item => {
                                                if (isFind)
                                                    return;
                                                const findOne = values.windows?.find(({ orientation }) => orientation === item);
                                                if (findOne == null) {
                                                    setOrientation(item);
                                                    isFind = true;
                                                    return;
                                                }
                                            });
                                        }
                                        add();
                                    }
                                    } block icon={<PlusOutlined />}>
                                        Add an orientation of windows
                                    </Button>
                                </Form.Item>
                                <Form.Item
                                    label = {<label style={{ color: "green" }}>The calculation is done as per ASHRAE Fundamentals Handbooks chapter 15.</label>}
                                />
                            </>
                        )}
                    </Form.List>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form >
        </>
    )
}

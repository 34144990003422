import './Sustainability.css'
import React from 'react'
import BannerSection from '@components/BannerSection'
import CommonSection from '@components/CommonSection'
import Certification from '@components/Certification'
import SustainabilityData from './data/SustainabilityData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

export default function Home() {

    return (
        <>
            <Navbar />
            <div>
                <BannerSection {...SustainabilityData.bannerSection} />
            </div>
            <div style={{marginLeft:'100px',marginRight:'100px', marginTop:'50px'}}>
                <div style={{fontWeight:"900", fontSize:"35px"}}>
                    From Small to Sustainable: How Start-Ups Companies Can Contribute to Earth Day Efforts and Making a Difference in Sustainability.
                </div>
                <br/>
                <div>
                    By Liliana Zhou, Marketing Lead of AI Shading
                </div>
                <br/>
                <div>
                    As we celebrate International Earth Day, it is important to reflect on the critical role that technology start-ups can play in fostering a sustainable future. These companies are uniquely positioned to innovate and develop pioneering solutions that promote eco-friendliness. In this article, we will explore some of the ways in which AI Shading, a small technology start-up, is contributing to promoting sustainability this International Earth Day. From designing intelligent shading systems to promoting sustainable printing habits and organizing Earth Day themed events, AI Shading demonstrates our commitment to a greener and more sustainable future. By sharing our experiences, we hope to inspire other start-ups to join the global effort towards a healthier planet for ourselves and future generations.
                </div>
                <br/>
                <div>
                    At AI Shading, we specialize in designing and implementing intelligent shading systems that use algorithms to optimize energy usage and reduce the carbon footprint of buildings. Our solution is tailored to contribute to a more sustainable future by minimizing the energy consumption of buildings, which is a leading cause of greenhouse gas emissions.
                </div>
                <br/>
                <div style={{fontWeight:"bold"}}>
                    Lighten up the Office - Sustainably
                </div>
                <br/>
                <BannerSection {...SustainabilityData.bannerSection2} />
                <br/>
                <div>
                    If your workplace has windows or skylights, open up the blinds and let in natural light! This not only reduces the need for artificial lighting but can also improve employee mood and productivity. Additionally, consider upgrading your office lighting to energy-efficient options to reduce your company's carbon footprint and cut down on energy expenses in the long term. Installing energy-saving lights is an inexpensive yet impactful way to contribute to a sustainable future.
                </div>
                <br/>
                <div>
                    At AI Shading, we have implemented sustainable practices within our own facilities, such as upgrading the bathroom lighting to motion-detecting options. This small but effective change not only reduces energy consumption but also provides a convenient and hygienic solution for employees.
                </div>
                <br/>
                <div style={{fontWeight:"bold"}}>
                    Modify Outdated Office Practices
                </div>
                <br/>
                <BannerSection {...SustainabilityData.bannerSection3} />
                <br/>
                <div>
                    To promote an environmentally conscious workplace, it's important to encourage sustainable printing habits among employees. This can include promoting double-sided printing and encouraging the use of digital alternatives for documents that can be easily shared electronically. Additionally, laptops are a more energy-efficient and eco-friendly option compared to desktop computers, as they produce less waste over time.
                </div>
                <br/>
                <div>
                    At AI Shading, we're committed to reducing paper usage by embracing digital payment methods. This eco-friendly approach not only lowers the amount of paper used in the office but also contributes to a more efficient and streamlined payment process. We're also taking steps to promote energy efficiency within our own office by transitioning to laptops instead of desktop computers. This small yet impactful change will lower our company's power usage and contribute to a more sustainable work environment.
                </div>
                <br/>
                <div style={{fontWeight:"bold"}}>
                    Green up the Workplace
                </div>
                <br/>
                <BannerSection {...SustainabilityData.bannerSection4} />
                <br/>
                <div>
                    Investing in small plants for employees on Earth Day is a simple yet highly effective way to promote sustainability in the workplace. Not only do plants add a natural aesthetic to the office, but they can also reduce employee stress levels and boost productivity, contributing to a sustainable work environment. Indoor plants are especially beneficial in tightly sealed buildings with limited ventilation, as they can remove toxins and pollutants from the indoor air quality.
                </div>
                <br/>
                <div>
                    By gifting employees with plants, the company demonstrates a commitment to environmental consciousness and sustainability, while encouraging sustainable habits and inspiring creativity and innovation. Celebrating Earth Day through this approach is an excellent opportunity to foster a more sustainable workplace culture and enhance the overall employee experience.
                </div>
                <br/>
                <div style={{fontWeight:"bold"}}>
                    Host an Earth Day Themed Company Lunch
                </div>
                <br/>
                <BannerSection {...SustainabilityData.bannerSection5} />
                <br/>
                <div>
                    A company lunch with an Earth Day theme can be a fun and interactive way to celebrate this important occasion while promoting sustainability in the workplace. At AI Shading, we're excited to host an Earth Day themed company lunch where employees are encouraged to bring their own reusable utensils and containers to reduce waste. The lunch will feature a variety of sustainable food options, such as locally sourced and organic produce, plant-based dishes, and eco-friendly packaging.
                </div>
                <br/>
                <div>
                    This event will also provide an opportunity for employees to learn about ways to promote sustainability in the workplace, including reducing energy consumption and minimizing waste. Through this educational and interactive experience, AI Shading hopes to inspire our team members to adopt more environmentally-conscious habits in their personal and professional lives. We believe that by working together, we can make a meaningful impact in protecting our planet and promoting a sustainable future.
                </div>
                <br/>
                <BannerSection {...SustainabilityData.bannerSection6} />
                <br/>
                <div>
                    Earth Day presents an excellent opportunity for small start-up companies to take action towards promoting sustainability in the workplace. Adopting simple yet impactful practices can make a significant difference in establishing an eco-friendly workplace culture. It's important to remember that even SMALL actions towards sustainability can make a BIG impact on the environment!
                </div>
                <br/>
                <div>
                    By making conscious choices and taking the first step towards sustainability, small start-up companies can join the global effort to create a healthier planet for ourselves and future generations. At AI Shading, we hope our sustainability efforts this Earth Day can inspire other small start-up companies to take action too.
                </div>
                <br/>
                <div>
                    Join us in creating a more eco-friendly workplace culture, while inspiring others to adopt sustainable practices within their own workplaces. Together, we can make a difference in protecting our planet and promoting a sustainable future for all.
                </div>
            </div>
            {/*<CommonSection*/}
            {/*    key='common-section'*/}
            {/*    color='#f5f5f5'*/}
            {/*    title="About Us"*/}
            {/*    description="AI Shading is a Canadian Smart & Green Technology company that provides economical solutions to help commercial buildings reach their emission reduction target by regulating indoor temperatures through a combination of smart indoor shades, sensors, mobile apps, and energy management platforms. AI Shading equips building managers with energy-saving reports to better analyze energy usage and track emission reduction goals."*/}
            {/*    btnTitle=''*/}
            {/*    btnLink=''*/}
            {/*    // image='/images/about.jpg'*/}
            {/*    // imageAlt="About Us"*/}
            {/*    imageAlign='right'*/}
            {/*    videoUrl='/videos/AI Shading Infomercial FINAL.mp4'*/}
            {/*/>*/}
            {/*<Certification*/}
            {/*    key='certification-section'*/}
            {/*    color='#fff'*/}
            {/*    title="Certification Credits"*/}
            {/*    description="AI Shading will help your project gain credits and achieved target certifications."*/}
            {/*    image='/images/certification.png'*/}
            {/*/>*/}
            <Footer />
        </>
    )
}

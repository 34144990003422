const SolutionData = {
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 350,
        title: 'Solution',
        background: '/images/banner-solution.jpg'
    }
};

export default SolutionData;
const SustainabilityData = {
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 400,
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/sustainability1.png'
    },
    bannerSection2: {
        color: '#fff',
        align: 'center',
        height: 400,
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/sustainability2.png'
    },
    bannerSection3: {
        color: '#fff',
        align: 'center',
        height: 400,
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/sustainability3.png'
    },
    bannerSection4: {
        color: '#fff',
        align: 'center',
        height: 400,
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/sustainability4.png'
    },
    bannerSection5: {
        color: '#fff',
        align: 'center',
        height: 400,
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/sustainability5.png'
    },
    bannerSection6: {
        color: '#fff',
        align: 'center',
        height: 400,
        description: '',
        btnTitle: '',
        btnLink: '',
        background: '/images/sustainability6.png'
    }
};

export default SustainabilityData;

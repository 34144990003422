const HomeData = {
    heroSection: {
        color: '#fff',
        align: 'left',
        title: 'AI-Shading',
        description: "AI Shading helps you save energy and meet your carbon reduction goals.",
        btnTitle: 'About Us',
        btnLink: '/about',
        background: '/images/hero-image3.jpg'
    },
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 350,
        title: 'Simulation calculation',
        description: "Now you can use our simulation system to calculate how much energy you can save with our solution.",
        btnTitle: 'Simulation',
        btnLink: '/simulation',
        background: '/images/Energy-saving.jpg'
    }
};

export default HomeData;
import './Newsroom.css'
import React from 'react'
// import HeroSection from '@components/HeroSection'
// import BannerSection from '@components/BannerSection'
// import CommonSection from '@components/CommonSection'
// import Certification from '@components/Certification'











// import FunctionList from '@components/FunctionList'
// import Divider from './conponents/Divider';
// import HeadLogo from '@components/head-logo'
// import RedSection from './components/RedSection'
// import HomeData from './data/HomeData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';
// import Divider from "../news/conponents/Divider";
// import DividerData from "../news/data/DividerData";
// import DividerData from "./data/DividerData";
export default function Newsroom() {

    return (
        <>
            <Navbar />

            <div className="background-color">

                {/*<iframe src="iframe.php?url=https://www.canva.com/design/DAFYI3gluj8" width="1000" height="800" frameBorder="0"></iframe>*/}




            </div>

            <Footer />

        </>
    )
}


const ContactData = {
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 350,
        title: 'Contact Us',
        background: '/images/banner-contact.png'
    }
};

export default ContactData;
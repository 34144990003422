import React, { useEffect } from 'react'
import { Form, Button, Input, Space, Modal } from 'antd'

export default function ReportForm({
    onSubmit,
    isModalOpen,
    setIsModalOpen
}) {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        // console.log('Success:', values);
        localStorage.setItem('reportInfo', JSON.stringify(values))
        setIsModalOpen(false)
        onSubmit(values)
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (form) {
            const getReportInfo = localStorage.getItem('reportInfo');
            if (getReportInfo) {
                const reportInfo = JSON.parse(getReportInfo);
                form.setFieldsValue(reportInfo)
            }
        }
    }, [form])

    return (
        <Modal
            title="Would you like to receive a detailed report?"
            open={isModalOpen}
            onOk={() => setIsModalOpen(false)}
            onCancel={() => setIsModalOpen(false)}
            footer={null}
        >
            <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout='vertical'
                form={form}
            >
                <Form.Item
                    label="Full Name"
                    name="fullname"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your full name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Phone Number (optional)"
                    name="phoneNumber"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Company Name (optional)"
                    name="companyName"
                >
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">
                            Yes, Please
                        </Button>
                        <Button type="default" onClick={() => onFinish(null)}>
                            No, Thanks
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    )
}

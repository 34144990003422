const SimulationData = {
    bannerSection: {
        color: '#fff',
        align: 'center',
        height: 350,
        title: 'Simulation',
        background: '/images/Energy-saving.jpg'
    },
    recommendedSettings: ['Calgary'],
    windowOrientation: ['North', 'East', 'South', 'West'],
    windowType: [
        {
            name: 'Single Glazing',
            value: '1b',
        },
        {
            name: 'Double Glazing',
            value: '5b',
        }
    ],
    windowSize: {
        width: 10,
        height: 5
    }
};

export default SimulationData;
import './Divider.css';
import React from 'react'
import { Link } from 'react-router-dom'

export default function Divider({
                                          color, align, height, title, description, btnTitle, btnLink, background
                                      }) {
    const styles = {
        backgroundImage: `url('${background}')`,
        color: color,
        textAlign: align,
        height: height
    }
    return (
        <div className='divider' style={styles}>
            <div className='banner-section-content'>
                <h3 className='fadein' dangerouslySetInnerHTML={{ __html: title }}></h3>
                {description && (<p className='fadein'>{description}</p>)}
                {btnTitle && (<Link to={btnLink} className='link-button common-button fadein'>{btnTitle}</Link>)}
            </div>
        </div>
    )
}
import './MessageForm.css'
import React, { useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { message as messageAPI } from '@services/api.service'
import { message } from 'antd'

export default function MessageForm() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [content, setContent] = useState('')
    const [result, setResult] = useState('')

    const [isNameInvalid, setIsNameInvalid] = useState(false)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleAction = async (params) => {
        try {
            const result = await messageAPI(params);
            return result;
        } catch (error) {
            const text = `Error: ${error.data?.message || 'please try again'}`;
            message.error(text);
            return null;
        }
    };

    const validateEmail = function (email) {
        var re = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
        return re.test(email)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsNameInvalid(false)
        setIsEmailInvalid(false)
        setIsSubmitted(false)
        setResult('')
        if (name === '') {
            setIsNameInvalid(true)
            setResult('Please enter your name')
            return
        }
        if (email === '' || !validateEmail(email)) {
            setIsEmailInvalid(true)
            setResult('Email is invalid')
            return
        }

        let resultMessage = await handleAction({
            fullname: name,
            email: email,
            title: subject,
            content: content
        })
        if (resultMessage != null) {
            setName('')
            setEmail('')
            setSubject('')
            setContent('')
            setIsSubmitted(true)
            setResult("Your message submited successfully!");
        } else {
            setResult(resultMessage)
        }
    }

    let resultStyle = isSubmitted ? 'success' : 'error'

    return (

        <div className='form-group'>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor='name'>Your Name (required)</label>
                    <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Name' />
                    {isNameInvalid && <p>The field is required.</p>}
                </div>
                <div>
                    <label htmlFor='email'>Your Email (required)</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type='email' placeholder='Email' />
                    {isEmailInvalid && <p>The field is required or invalid.</p>}
                </div>
                <div>
                    <label htmlFor='subject'>Subject</label>
                    <input value={subject} onChange={(e) => setSubject(e.target.value)} type='text' placeholder='Subject' />
                </div>
                <div>
                    <label htmlFor='message'>Your Message</label>
                    <textarea value={content} onChange={(e) => setContent(e.target.value)} placeholder='Message' />
                </div>
                <div>
                    <button type='submit' className='common-button'>SEND</button>
                </div>
                {result &&
                    <div className={resultStyle}>
                        <label><AiFillInfoCircle /> {result}</label>
                    </div>
                }
            </form>
        </div>

    )
}

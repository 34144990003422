import './Solution.css'
import React from 'react'
import BannerSection from '@components/BannerSection'
import CommonSection from '@components/CommonSection'
import SolutionData from './data/SolutionData'
import Navbar from '@components/Navbar';
import Footer from '@components/Footer';

export default function Solution() {

    return (
        <>
            <Navbar />
            <BannerSection {...SolutionData.bannerSection} />
            {/*<CommonSection*/}
            {/*    key='contact-section'*/}
            {/*    color='#fff'*/}
            {/*    title="Solution"*/}
            {/*    description=" "*/}
            {/*    imageAlign='left'*/}
            {/*    // videoUrl='/videos/AI Shading Pilot Project @ SAIT GBT.mp4'*/}

            {/*/>*/}
            <CommonSection
                key='contact-section'
                color='#fff'
                btnLink='/about'
                imageAlt="Contact Us"
                imageAlign='middle'
                videoUrl='/videos/AI Shading Pilot Project @ SAIT GBT.mp4'
            />


            <Footer />
        </>
    )
}

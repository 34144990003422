import './Navbar.css'
import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react'
import { BiArrowToTop } from 'react-icons/bi'
import { Link, NavLink } from 'react-router-dom'
import {
    AiOutlineMenu, AiOutlineClose
} from 'react-icons/ai'
import MessageForm from './MessageForm'
export default function Navbar() {
    const [isShowMenu, setIsShowMenu] = useState(false);
    const [isShowGoTop, setIsShowGoTop] = useState(false);

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    useEffect(() => {
        windowSizeChange();
    }, [])

    const handleClick = () => {
        if (window.innerWidth > 960) {
            return;
        }
        setIsShowMenu(!isShowMenu)
    };
    const windowSizeChange = () => {
        if (window.innerWidth < 960) {
            setIsShowMenu(false)
        } else {
            setIsShowMenu(true)
        }
    };
    window.addEventListener('resize', windowSizeChange)
    window.addEventListener('scroll', () => {
        if (window.scrollY > 50) {
            document.querySelector('.navbar').classList.add('navbar-scroll');
        } else {
            document.querySelector('.navbar').classList.remove('navbar-scroll');
        }
        setIsShowGoTop(window.scrollY > 200);
    })
    const gotoHome = () => {
        if (window.innerWidth < 960) {
            setIsShowMenu(false)
        } else {
            setIsShowMenu(true)
        }
        window.scrollTo(0, 0)
    }
    // const BLOCK = { display: 'flex' }
    // const NONE = { display: 'none' }
    const navStyle = isShowMenu ? { display: 'flex' } : { display: 'none' };
    return (
        <header>
            <Drawer title="Message Form" width={500} placement="right" onClose={onClose} open={visible}>
                <MessageForm />
            </Drawer>
            {isShowGoTop && (
                <button className="common-button goToTop" onClick={() => {
                    window.scrollTo(0, 0);
                }}>
                    <BiArrowToTop size={24} />
                </button>
            )}
            <nav className='navbar'>
                <div className="nav-list">
                    <div className="nav-logo">
                        <Link to="/" onClick={gotoHome}>
                            <img width="100" src="/images/logo.png" alt="" />
                        </Link>
                    </div>
                    <div className="nav-content" style={navStyle}>
                        <div className="nav-links">
                            <NavLink className={({ isActive }) => isActive ? 'active' : undefined} to="/" onClick={gotoHome}>HOME</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'active' : undefined} onClick={handleClick} to="/about">ABOUT</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'active' : undefined} onClick={handleClick} to="/solution">SOLUTION</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'active' : undefined} onClick={handleClick} to="/simulation">SIMULATION</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'active' : undefined} onClick={handleClick} to="/sustainability">SUSTAINABILITY</NavLink>
                            <NavLink className={({ isActive }) => isActive ? 'active' : undefined} onClick={handleClick} to="/contact">CONTACT</NavLink>
                            {/*<NavLink className={({ isActive }) => isActive ? 'active' : undefined} onClick={handleClick} to="/newsroom">NEWSROOM</NavLink>*/}
                            <a href="https://www.canva.com/design/DAFYI3gluj8/view" target = "_blank" rel="noreferrer">NEWS</a>
                            <button className="menu-contact-button btn-contact" onClick={showDrawer}>MESSAGE NOW</button>
                        </div>
                    </div>
                    <div className="nav-menu">
                        {isShowMenu ? <AiOutlineClose onClick={handleClick} /> : <AiOutlineMenu onClick={handleClick} />}
                    </div>
                </div>
            </nav>
        </header>
    )
}

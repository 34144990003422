import './Footer.css'
import React from 'react'
import { config } from '../Config'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer>
            <div className='footer-content'>
                <div className='footer-about'>
                    <img src='./images/logo.png' width='100' alt='' />
                    <p>AI Shading is a Canadian Smart & Green Technology company that provides economical solutions to help commercial buildings reach their emission reduction target by regulating indoor temperatures through a combination of smart indoor shades, sensors, mobile apps, and energy management platforms.</p>
                </div>
                <div className='footer-links'>
                    <h3>Quick Links</h3>
                    <ul>
                        <li><Link to='/'>Home</Link></li>
                        <li><Link to='/about'>About Us</Link></li>
                        <li><Link to='/solution'>Solution</Link></li>
                        <li><Link to='/simulation'>Simulation</Link></li>
                        <li><Link to='/contact'>Contact Us</Link></li>
                        <li><a href={"https://drive.google.com/file/d/1-SDODBp76B4zMqyBIpHROfk2ht5SGgql/view?usp=sharing"} target = "_blank" rel="noreferrer">Case Study</a></li>
                        <li><a href="https://drive.google.com/file/d/16OSCL2vmt6lwReRLNO5HD4iQkH1kkau8/view?usp=sharing" target = "_blank" rel="noreferrer">Published Journal</a></li>
                    </ul>
                </div>
                <div className='footer-media'>
                    <h3>Social Media</h3>
                    <ul>
                        <li><a href={config.facebook} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-facebook.png" alt="facebook" /></a></li>
                        <li><a href={config.instagram} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-instagram.png" alt="instagram" /></a></li>
                        {/* <li><a href={config.twitter} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-twitter.png" alt="twitter" /></a></li>
                         */}
                         <li><a href={config.linkin} target="_blank" rel="noreferrer"><img width="50" src="/images/footer-linkin.png" alt="twitter" /></a></li>
                    </ul>
                </div>
                <div className="footer-copyright">
                    Copyright © 2022 AI-Shading. All Rights Reserved.
                </div>
                {/*<div className="case study">*/}
                {/*    <li><a href={"https://drive.google.com/file/d/1-SDODBp76B4zMqyBIpHROfk2ht5SGgql/view?usp=sharing"}>Case Study</a></li>*/}
                {/*    <li><a href="https://drive.google.com/file/d/16OSCL2vmt6lwReRLNO5HD4iQkH1kkau8/view?usp=sharing">Published Journal</a></li>*/}
                {/*</div>*/}
            </div>
        </footer>
    )
}

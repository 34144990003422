import './LandingHeroSection.css';
import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import { Select, Button, Steps, message, Form, Space, Input } from "antd"
import { AiFillCaretDown } from 'react-icons/ai'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import SimulationData from '../pages/simulation/data/SimulationData'
import ReportForm from '../pages/simulation/components/ReportForm'
import { useNavigate } from 'react-router-dom';

const { Step } = Steps;

const steps = [
    {
        title: 'select a city',
        content: 'First-content',
    },
    {
        title: 'set windows',
        content: 'Second-content',
    }
];

export default function LandingHeroSection({
    color, align, title, description, btnTitle, btnLink, background
}) {
    const styles = {
        backgroundImage: `url('${background}')`,
        color: color,
        textAlign: align
    }

    const navigate = useNavigate();

    const [current, setCurrent] = useState(0);
    const [form] = Form.useForm();
    const [city, setCity] = useState('Calgary')
    const [orientation, setOrientation] = React.useState('North');
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const onSubmit = (values) => {
        const datas = form.getFieldsValue(true);
        const landingData = {
            city: city,
            window_type: '1b',
            width: 10,
            height: 5,
            ...values,
            ...datas
        };
        localStorage.setItem('reportInfo', JSON.stringify(values))
        localStorage.setItem('landingData', JSON.stringify(landingData))
        // jump to simulation page
        navigate('/simulation')
    }

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onFinish = (values) => {
        if (values.windows == null || values.windows.length === 0) {
            message.error('Please add an orientation');
            return;
        }
        const getReportInfo = localStorage.getItem('reportInfo');
        if (getReportInfo) {
            const reportInfo = JSON.parse(getReportInfo);
            onSubmit(reportInfo);
            return;
        }
        setIsModalOpen(true);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='landing-hero-section' style={styles}>
            <ReportForm isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onSubmit={onSubmit} />
            <div className='landing-hero-section-content'>
                <h3 className='landing-fadein' dangerouslySetInnerHTML={{ __html: title }}></h3>
                <p className='landing-fadein'>{description}</p>
                <div className='landing-form'>
                    <AiFillCaretDown className='arrow-icon' size={32} />
                    <div className='landing-form-content'>
                        <h4>SAVE ENERGY IN JUST 2 STEPS</h4>
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <Form
                            name="basic"
                            layout='vertical'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            form={form}
                        >
                            {current === 0 && (
                                <Space>
                                    <Select
                                        value={city}
                                        style={{ textAlign: 'center', fontSize: 20, width: '100%' }}
                                        options={[{ label: "Calgary", value: "Calgary" }]}
                                        onChange={(value) => {
                                            setCity(value)
                                        }}
                                    />
                                    <Button type="primary" onClick={() => next()}>
                                        Next
                                    </Button>
                                </Space>
                            )}
                            {current === 1 && (
                                <Form.Item label="Window List" noStyle={true}>
                                    <Form.List
                                        name="windows" initialValue={[
                                            { orientation: 'East', number: 10 },
                                            { orientation: 'South', number: 10 },
                                            { orientation: 'West', number: 10 }
                                        ]}>
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field) => (
                                                    <Space
                                                        key={field.key}
                                                        style={{
                                                            display: 'flex',
                                                        }}
                                                        align="baseline"

                                                    >
                                                        {/* Orientation: */}
                                                        <Form.Item
                                                            {...field.name}
                                                            name={[field.name, 'orientation']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'select an orientation',
                                                                },
                                                            ]}
                                                            initialValue={orientation}
                                                        >
                                                            <Select
                                                                style={{ width: '120px' }}
                                                                options={SimulationData.windowOrientation.map(item => ({ value: item, label: item }))}
                                                            />
                                                        </Form.Item>
                                                        Number:
                                                        <Form.Item
                                                            {...field.name}
                                                            name={[field.name, 'number']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'input number',
                                                                },
                                                            ]}
                                                            initialValue={10}
                                                        >
                                                            <Input
                                                                type='number'
                                                                placeholder="Number"
                                                            />
                                                        </Form.Item>
                                                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                    </Space>
                                                ))}
                                                <Form.Item>
                                                    <Button type="dashed" onClick={() => {
                                                        const windows = form.getFieldValue('windows');
                                                        if (windows?.length >= 4) {
                                                            message.error('Add up to four orientations')
                                                            return;
                                                        }
                                                        const values = form.getFieldsValue(true);
                                                        console.log('values', values)
                                                        if (values) {
                                                            let isFind = false;
                                                            SimulationData.windowOrientation.forEach(item => {
                                                                if (isFind)
                                                                    return;
                                                                const findOne = values.windows?.find(({ orientation }) => orientation === item);
                                                                if (findOne == null) {
                                                                    setOrientation(item);
                                                                    isFind = true;
                                                                    return;
                                                                }
                                                            });
                                                        }
                                                        add();
                                                    }
                                                    } block icon={<PlusOutlined />}>
                                                        Add an orientation of windows
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Form.Item>
                            )}
                            <Form.Item noStyle={true}>
                                <Space>
                                    {current === steps.length - 1 && (
                                        <Space>
                                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                                Previous
                                            </Button>
                                            <Button type='primary' htmlType="submit">Start the Simulation</Button>
                                        </Space>
                                    )}
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

import './Function.css';
import React from 'react'
// import { Link } from 'react-router-dom';
import { message } from 'antd';

export default function FunctionItem({
    title,
    description,
    btnTitle,
    btnLink,
    image
}) {

    const handleClick = () => {
        message.info('Coming soon...');
    }
    return (
        <div className='function-item'>
            <img src={image} alt={title} width='100%' />
            <div className='content'>
                <h3>{title}</h3>
                <p>{description}</p>
                {/* <Link to={btnLink}><button className='common-button'>{btnTitle}</button></Link> */}
                <button onClick={handleClick} className='common-button'>{btnTitle}</button>
            </div>
        </div>
    )
}

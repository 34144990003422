import React from 'react'
import { Column } from '@ant-design/plots';

export default function ChartMonths({
    monthList
}) {
    const config = {
        data: monthList,
        isGroup: true,
        xField: 'month',
        yField: 'value',
        seriesField: 'name',
        legend: {
            position: 'top',
        },
        yAxis: {
            label: {
                // 数值格式化为千分位
                formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
            title: {
                // text: showData.y_label,
                text: 'Electricity Savings($CAD)',
            },
        },
        xAxis: {
            title: {
                text: 'Month',
            },
        },
        /** 设置颜色 */
        color: ['#55B670', '#F59D00'],

        /** 设置间距 */
        // marginRatio: 0.1,
        label: {
            // 可手动配置 label 数据标签位置
            position: 'middle',
            // 'top', 'middle', 'bottom'
            // 可配置附加的布局方法
            layout: [
                // 柱形图数据标签位置自动调整
                {
                    type: 'interval-adjust-position',
                }, // 数据标签防遮挡
                {
                    type: 'interval-hide-overlap',
                }, // 数据标签文颜色自动调整
                {
                    type: 'adjust-color',
                },
            ],
        },
    };

    return (
        <div className='chart-wrap'>
            <h3>Monthly Energy Savings</h3>
            <Column {...config} />
        </div>

    )
}
